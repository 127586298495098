@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

body {
  font-family: "Poppins", Helvetica, sans-serif;
  font-size: 12px !important;
}

/* * {
  scroll-behavior: smooth;
} */

.table-responsive {
  max-height: 70vh;
}

.text-green {
  color: #39bc0c;
}

.bg-green {
  background: #39bc0c !important;
}

.dropdown-menu {
  width: auto;
}

.main-heading {
  font-size: 28px;
  font-weight: 600;
}

.pointer {
  cursor: pointer;
}

td .dtls-wit-icn {
  display: flex;
  align-items: baseline;
}

td .edit-icn {
  font-size: 12px;
}

button.edit-order {
  border: none;
  background: #3699ff;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
  margin-top: 3px;
  padding: 0px 6px;
  outline: none !important;
  font-size: 1rem;
}

button.add-order {
  border: none;
  background: #1bc5bd;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
  margin-top: 3px;
  padding: 0px 6px;
  outline: none !important;
  font-size: 1rem;
}

button.add-order.upload {
  padding: 2px 8px;
}

button.edit-order i,
button.add-order i {
  font-size: 12px;
  color: #fff;
  margin-right: 3px;
}

.font-semibold {
  font-weight: 600;
}

input.form-control,
textarea.form-control {
  font-weight: 400;
}

/* ======================================================================= */
/*=============================== Order Edit ==============================*/
/* ======================================================================= */
.order-edit .head-select {
  background-color: #fff5f8;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-right: 12px;
  width: 165px;
  border: 1px solid #fff5f8;
}

.order-edit .head-select span {
  font-size: 12px;
  padding-left: 13px;
}

.order-edit .head-select select {
  font-size: 12px;
  border: none;
  background-color: #fff5f8;
  background-image: url("./assets/img/down-arrow-primary.png");
  background-size: 16px 16px;
  border-radius: 5px;
  padding-left: 5px;
}

.order-edit .head-select .inv-button {
  font-size: 12px;
  width: 165px;
}

/* .order-edit .select-title {
  position: absolute;
  top: 12px;
  left: 13px;
  font-size: 12px;
} */

.order-edit .head-select select.text-danger {
  background-image: url("./assets/img/down-arrow-red.png");
}

.order-edit .dashed {
  border: 1px solid #a1a5b7;
  border-radius: 5px;
  margin-right: 10px;
  padding: 10px 15px;
  border-style: dashed;
}

.order-edit .dashed p {
  font-size: 14px;
}

.order-edit .dashed span {
  font-size: 12px;
}

.order-edit .cro-mt span {
  font-size: 14px;
}

.order-edit .cro-mt select {
  color: #a1a5b7;
  border: none;
  background-color: #eff2f5;
  margin-top: 5px;
  padding: 15px 20px;
  width: 165px;
  font-size: 14px;
}

.order-edit .cro-mt.cro select {
  color: #c7cad4;
  background-color: #f6f8fa;
}

/* .order-edit .cro-mt {
  margin-left: 10px;
} */

.order-edit .ref-h {
  padding: 17px 20px;
  width: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eff2f5;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 0.475rem;
  margin-top: 2px;
}

.order-edit .ref-h input {
  width: 100%;
  border: none;
  outline: none;
  background-color: #eff2f5;
  margin-left: 5px;
  font-size: 14px;
  color: #a1a5b7;
}

.order-edit .ref-h span {
  font-size: 14px;
}

.order-edit .menu-lists {
  list-style: none;
  padding-left: 0;
  display: flex;
  margin-bottom: 0;
  justify-content: space-between;
}

.order-edit .menu-lists li,
.order-edit .menu-lists a {
  margin-right: 7px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  color: #1d1d1d;
}

.order-edit .menu-lists li a {
  color: #1d1d1d;
}

.order-edit .menu-lists a.active {
  color: #31b5c4;
  border-bottom: 2px solid #31b5c4;
}

.order-edit .name-vertical {
  text-transform: uppercase;
  margin: 0;
  font-size: 16px;
  letter-spacing: 3px;
  transform: rotate(-180deg);
  writing-mode: vertical-rl;
  margin-left: auto;
  display: flex;
}

.order-edit .section-name-right {
  display: flex;
  align-items: center;
  color: #a1a5b7;
}

.order-edit .padd-fir-item {
  padding-right: 50px;
}

.order-edit .padd-fir-item.report-left {
  width: 64%;
}

.order-edit .padd-last-item.report-right {
  width: 36%;
}

.order-edit .padd-last-item .items {
  max-height: 350px;
  overflow: scroll;
}

.order-edit .item-title {
  font-size: 15px;
  margin-bottom: 12px;
  display: inline-block;
}

.order-edit .edit-option {
  padding: 20px 60px 20px 20px;
  border-radius: 5px;
  background-color: #f1faff;
  position: relative;
  font-size: 14px;
  margin-bottom: 10px;
  
  border-width: 1px;
  border-color: #FFF0;
  border-style: solid;
}

.order-edit .prev-addrs{
  padding: 20px 60px 20px 20px;
}

.order-edit .selectedAddrs {
  background: #e4f3fc;
  border-width: 1px;
  border-color: #7991a7;
  border-style: solid;
}

.order-edit .edit-option.note-edit {
  justify-content: space-between;
  min-height: 150px;
  display: flex;
  flex-direction: column;
}

.order-edit .edit-option .date {
  font-size: 10px;
  color: #787887;
}

.order-edit .padd-last-item .notes-all {
  max-height: 350px;
  overflow: scroll;
}

.order-edit .edit-option.loop {
  margin-bottom: 10px;
  margin-right: 5px;
}

.order-edit .edit-option.loop.user {
  border: 1px solid #777;
}

.order-edit .edit-option i {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 18px;
  cursor: pointer;
}

.order-edit .edit-option i.edit {
  right: 46px;
}

.order-edit .edit-option i.selected {
  right: 80px;
  margin-top: 1px;
  font-size: 19px;
  color: #4ab5cc;
  cursor: default;
}

.order-edit .edit-option i.edit-selected {
  right: 52px !important;
}

.order-edit .padd-fir-item textarea {
  border: none;
  background-color: #eff2f5;
  padding: 19px 20px;
}

.order-edit .submit-btn {
  min-width: 140px;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  text-transform: uppercase;
}

.order-edit .padd-last-item .item-title {
  border-bottom: 1px solid #333;
}

.order-edit .padd-fir-item input,
.order-edit .padd-fir-item select {
  background-color: #eff2f5;
  border-color: #eff2f5;
  color: #a1a5b7;
  padding: 15px 20px;
  font-weight: 400;
}

.order-edit .padd-fir-item .react-datepicker__input-container input {
  border: 1px solid #eff2f5;
  border-radius: 5px;
  color: #a1a5b7;
  padding: 15px 20px;
  width: 99.7%;
  outline: none;
  margin-bottom: 18px;
}

.order-edit .react-datepicker-wrapper {
  width: 100%;
}

.order-edit .head-card {
  position: sticky;
  top: -200px;
  margin-bottom: 10px;
  z-index: 1;
}

.order-edit .col-6in7-l {
  padding-right: 15px;
}

.order-edit .col-6in7-r {
  padding-left: 15px;
}

.order-edit .reports .single:not(:last-child) {
  margin-bottom: 15px;
}

.order-edit .reports .single .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f1faff;
  padding: 10px 15px;
  border-radius: 5px;
}

.order-edit .reports .single .head button {
  background-color: #cfeeff;
  padding: 10px 13px !important;
}

.order-edit .reports .single .head h5 {
  font-weight: 500;
}

.order-edit .reports .single .body {
  padding: 15px 0px;
}

.order-edit .pdf {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-edit .pdf:not(:last-child) {
  margin-bottom: 10px;
}

.order-edit .pdf .left {
  display: flex;
  align-items: center;
  color: #787887;
}

.order-edit .pdf .left img {
  width: 25px;
  margin-right: 13px;
}

.order-edit .pdf .left .name-date .name {
  font-size: 12px;
  margin-bottom: 2px;
  font-weight: 500;
  color: #787887;
}

.order-edit .pdf .left .name-date .date {
  font-size: 10px;
  margin-bottom: 0;
}

.order-edit .pdf .right img {
  width: 20px;
  cursor: pointer;
  transform: rotate(45deg);
}

.order-edit .pdf .right img.download {
  transform: rotate(0deg);
  height: 17px;
  margin-right: 5px;
}

.order-edit .report-email-head div {
  background-color: #f1faff;
  border-radius: 5px;
  margin-top: 60px;
}

.order-edit .report-email-head h2 {
  font-size: 14px;
  text-align: center;
  padding-top: 23px;
  padding-bottom: 23px;
  margin-bottom: 24px;
  text-transform: uppercase;
}

.order-edit .log-btn {
  font-size: 12px;
  padding: 5px 16px !important;
  width: 110px;
  margin-right: 20px;
  text-transform: capitalize;
}

.order-edit .email-log.items .single:not(:last-child) {
  margin-bottom: 10px;
}

.order-edit .css-yk16xz-control,
.order-edit .css-1pahdxg-control {
  min-height: 53px;
  border: 1px solid transparent;
  border-radius: 0.475rem;
  padding: 0 7px;
  font-size: 14px;
  color: #5f6278;
  background-color: #eff2f5;
}

.order-edit .mt-details {
  align-items: flex-start !important;
  /* flex-direction: column; */
}

.order-edit .mt-details .mt-status {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.order-edit .mt-details .mt-status .icon i {
  font-size: 16px;
}

.order-edit .mt-details .mt-status .name {
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
}

.order-edit .mt-details .mt-status button {
  padding: 5px 7px 5px 12px !important;
  /* margin-left: 15px; */
}

.order-edit .mt-details .mt-status button:hover i {
  color: #fff !important;
}

.order-edit .mt-status2 {
  display: flex;
  align-items: baseline;
}

.order-edit .mt-status2 .title {
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 15px;
  text-transform: capitalize;
}

.order-edit .mt-status2 .title span.pending {
  color: #ffc700;
}

.order-edit .mt-status2 .title span.cancel {
  color: #f1416c;
}

.order-edit .mt-status2 .title span.ongoing,
.order-edit .mt-status2 .title span.completed,
.order-edit .mt-status2 .title span.go_to_lab {
  color: #50cd89;
}

.order-edit .mt-status2 .down_his_ic button {
  padding: 5px 7px 5px 12px !important;
}

.order-edit .mt-status2 .down_his_ic button:hover i {
  color: #fff !important;
}

.ord-edt-itm-mdl .modal-close {
  min-width: 135px;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  text-transform: uppercase;
  background-color: #f5f5f5;
}

.ord-edt-itm-mdl h5.title {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 500;
}

.ord-edt-itm-mdl .patients .lists .box {
  border: 1px solid #f6f8fa;
  border-radius: 6px;
  padding: 16px 20px;
  margin-bottom: 15px;
}

.ord-edt-itm-mdl .patients .lists .box.user {
  border: 1px solid #0080ff;
}

.ord-edt-itm-mdl .patients .lists .single {
  display: block;
  position: relative;
  padding-left: 40px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
  margin-bottom: 0;
}

.ord-edt-itm-mdl .patients .lists .single input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.ord-edt-itm-mdl .patients .lists .single input:checked ~ .checkmark {
  background-color: #31b5c4;
}

.ord-edt-itm-mdl .patients .lists .single .checkmark {
  position: absolute;
  top: 25px;
  left: 0;
  height: 23px;
  width: 23px;
  background-color: #eee;
  border-radius: 50%;
}

.ord-edt-itm-mdl .patients .lists .single .details span {
  font-size: 12px;
  display: block;
  color: #5f6278;
  text-transform: capitalize;
}

.ord-edt-itm-mdl .patients .lists .single .details .buttons {
  position: absolute;
  top: -10px;
  right: 0;
}

.ord-edt-itm-mdl .patients .lists .single .details .buttons i {
  font-size: 16px;
  cursor: pointer;
}

.ord-edt-itm-mdl .patients .lists .single .details span.name {
  font-size: 14px;
  color: #404254;
}

.ord-edt-itm-mdl .patients .lists .single .details span:not(:last-child) {
  margin-bottom: 5px;
}

.ord-edt-itm-mdl .patients .lists {
  max-height: 245px;
  overflow: scroll;
}

.ord-edt-itm-mdl .patients .add-patient i {
  font-size: 20px;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 10px;
  cursor: pointer;
}

.ord-edt-itm-mdl .tests select {
  font-size: 14px;
  padding: 18px 20px;
  color: #5f6278;
}
.ord-edt-itm-mdl .css-yk16xz-control,
.ord-edt-itm-mdl .css-1pahdxg-control {
  min-height: 58px;
  border: 1px solid #e4e6ef;
  border-radius: 0.475rem;
  padding: 0 7px;
  font-size: 14px;
  color: #5f6278;
}

.ord-edt-itm-mdl .css-1pahdxg-control:hover {
  border-color: initial;
}

.ord-edt-itm-mdl .submit-add-btn {
  min-width: 135px;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  text-transform: uppercase;
}

.pat-add-modal button.sub {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  text-transform: uppercase;
  min-width: 170px;
}

.pat-add-modal .dob-inp .react-datepicker__input-container input {
  border: 1px solid #e4e6ef;
  border-radius: 5px;
  padding: 15px 20px;
  width: 100%;
  outline: none;
  min-height: calc(1.5em + 1.65rem + 2px);
  padding: 0.825rem 1.5rem;
  font-size: 1.15rem;
  border-radius: 0.475rem;
}

.pat-add-modal .dob-inp .react-datepicker-wrapper {
  width: 100%;
}

/* ======================================================================= */
/*================================= Login =================================*/
/* ======================================================================= */
.login_page .height {
  min-height: 100vh;
}
.login.login-4 .login-signin,
.login.login-4 .login-signup,
.login.login-4 .login-forgot {
  display: none;
}
.login.login-4.login-signin-on .login-signup {
  display: none;
}
.login.login-4.login-signin-on .login-signin {
  display: block;
}
.login.login-4.login-signin-on .login-forgot {
  display: none;
}
.login.login-4.login-signup-on .login-signup {
  display: block;
}
.login.login-4.login-signup-on .login-signin {
  display: none;
}
.login.login-4.login-signup-on .login-forgot {
  display: none;
}
.login.login-4.login-forgot-on .login-signup {
  display: none;
}
.login.login-4.login-forgot-on .login-signin {
  display: none;
}
.login.login-4.login-forgot-on .login-forgot {
  display: block;
}

.login_page .passw {
  position: relative;
}

.login_page .passw img {
  position: absolute;
  top: 25%;
  right: 7%;
  cursor: pointer;
}

.login.login-4 .login-form {
  width: 100%;
  max-width: 450px;
}

@media (max-width: 575.98px) {
  .login.login-4 .login-form {
    width: 100%;
    max-width: 100%;
  }
}

/*======================== Homepage ===========================*/
.homepage .single {
  /* border: 1px solid #3f4254; */
  border-radius: 5px;
  padding: 30px 0;
}

.homepage .single h1 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.homepage .single p {
  text-align: center;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}

.homepage p.button button {
  color: #3f4254;
  background: #e6e6e6;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 25px;
  text-transform: uppercase;
  border: 1px solid #e6e6e6;
}

.homepage p.button button:hover {
  color: #3f4254 !important;
  background: #e6e6e6 !important;
  border: 1px solid #e6e6e6;
  cursor: unset;
}

.menu-sub-dropdown.menu.avatar.show {
  z-index: 105;
  position: fixed;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate(-30px, 65px);
}

/**/
.symbol.symbol-60px.symbol-2by3 > i {
  height: 35px;
  width: 48px;
}

/* 4cb6c4 */
.daterangepicker {
  position: fixed;
  font-family: "Poppins", Helvetica, sans-serif;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 10px 0 rgba(0, 0, 0, 0.1);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #31b5c4 !important;
  border-color: transparent;
  color: #fff;
}
.daterangepicker .ranges li.active {
  background-color: #31b5c4;
  color: #fff;
}

.daterangepicker td.in-range {
  background-color: #e5f3f5;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker .ranges {
  min-width: 170px;
  padding-bottom: 10px;
}
.daterangepicker .ranges ul {
  width: 100%;
  color: #7c8399;
}

.daterangepicker .right th,
.daterangepicker .left th {
  color: #707070;
}
.daterangepicker .drp-buttons {
  border-top-width: 0;
}

/*====================== Test / Package ========================*/
.test-page table td {
  font-size: 1rem;
}

/*=================== Test / Package Single ====================*/
.page-test-details table td {
  font-size: 1rem;
}

/*=============================== Chars ======================*/
.mixed-widget-4-chart.circle-ch {
  min-height: 225px;
}
/* #hide-translate g {
  transform: translate(0, 0);
}

#apexchartstodaycompletionchart {
  max-width: 100%;
}
#apexchartstodaycompletionchart svg,
#apexchartstodaycompletionchart g {
  width: 100%;
} */

/*====================== Order History ========================*/
.order-history {
  padding-top: 20px;
  padding-bottom: 40px;
}

.order-history table thead tr th {
  background: #eee;
}

.order-history table tbody tr td p {
  font-size: 1rem;
}

.order-history table tbody tr td a.download {
  font-size: 1rem;
  font-weight: 500;
  margin-left: 5px;
}

.order-history table tbody tr:hover {
  background: #f7f7f7;
}

.order-history table tbody select {
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 2px 4px;
  border: 1px solid #a1a1a1;
  background-color: transparent;
}
.order-history table tbody textarea {
  outline: none;
  border: 1px solid #a1a1a1;
  border-radius: 5px;
  padding: 2px 4px;
  width: 185px;
  margin-right: 8px;
  min-height: 65px;
}

.order-history table tbody input {
  outline: none;
  border: 1px solid #a1a1a1;
  border-radius: 5px;
  padding: 2px 4px;
  width: 185px;
  margin-right: 8px;
  height: 30px;
}

.order-history table tbody input.upload {
  /* border: none; */
  margin-top: 10px;
  padding-top: 4px;
}

.order-history table tbody .last {
  margin-top: 8px;
}

.order-history h5.main-heading {
  margin-bottom: 40px;
}

.order-history .single {
  background: #f0f0f5;
  padding: 38px 45px 45px 45px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.order-history .single .header,
.order-history .single .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-history .single .header {
  margin-bottom: 5px;
}

.order-history .single .header .left .id {
  font-size: 18px;
  font-weight: 600;
  color: #1d1d1d;
  margin-bottom: 10px;
}

.order-history .single .header .left .date {
  font-size: 16px;
  color: #1d1d1d;
  margin-bottom: 0;
}

.order-history .single .header .right p {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.order-history .single .footer .left {
  margin-bottom: 0;
  font-size: 16px;
}

.order-history .single .footer .left span {
  font-weight: 600;
}

.order-history .single .footer .right a {
  width: 100%;
  margin-top: 5px;
}

.order-history .single.unpaid .footer .right button.pay {
  margin-left: 15px;
  background: #2f932f;
}

.order-history .single.download .footer .right button.dnld {
  margin-right: 15px;
  width: 210px;
  border: 1.5px solid #31b5c4;
}

.order-history .single.download .footer .right button.dnld:hover {
  background: #f4f4f4;
}

.order-history .single.download .footer .right button.dnld img {
  width: 23px;
  margin-right: 10px;
}

.order-history .pagi button {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 11px 25px;
  background: #f0f0f5;
  margin-top: 30px;
}

td .order-itm.active {
  max-height: 220px;
  overflow-y: auto;
}

td .order-itm.active::-webkit-scrollbar {
  width: 5px;
}

td .order-itm.active::-webkit-scrollbar-track {
  background: #fff;
}

td .order-itm.active::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 5px;
}

td .order-itm.active::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 6px;
  font-size: 13px;
}

/*============================= Aside =============================*/
.aside-menu .menu > .menu-item > .menu-link {
  width: 100%;
}
.aside-menu .menu > .menu-item > .menu-link .menu-icon i {
  font-size: 15px !important;
}

@media only screen and (min-width: 992px) {
  .container.small {
    min-width: 100%;
  }
  .aside {
    width: 150px;
  }

  .aside-enabled.aside-fixed .wrapper {
    padding-left: 150px;
  }

  .aside .aside-logo {
    height: 13vh;
  }

  .aside .aside-menu {
    height: 87vh;
    overflow: scroll;
  }

  .aside .aside-menu::-webkit-scrollbar-thumb {
    background: #4b4b6f;
  }
}

@media only screen and (max-width: 991px) {
  .aside {
    width: 170px;
    transition: all 0.5s;
  }
}

/*============================= Delete Confirmation =============================*/
.delete_confirmation .icon img {
  width: 100px;
  margin-bottom: 1.5rem;
}

.delete_confirmation .details h5 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1.2rem;
}

.delete_confirmation .details p {
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 1.8rem;
}

.delete_confirmation .buttons button {
  width: 80px;
  font-size: 16px;
}

/*============================= Order Add Edit =============================*/
.create-edit-order .col-md-6.left {
  padding-right: 25px;
}

.create-edit-order .col-md-6.right {
  padding-left: 25px;
}

.create-edit-order .order-summery {
  border: 1px solid #e4e6ef;
  border-radius: 0.475rem;
}

.create-edit-order .order-summery h5 {
  margin-bottom: 1.5rem;
}

.create-edit-order .order-summery .list {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: 5px;
}

.create-edit-order .order-summery .break {
  height: 1px;
  width: 100%;
  background: #2d2d32;
  margin-bottom: 5px;
}

.create-edit-order .carts .lists .single h5 {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  margin-bottom: 5px;
}

.create-edit-order .carts .lists .single h5 img {
  width: 13px;
  cursor: pointer;
  margin-right: 15px;
}

.create-edit-order .carts .lists .single p {
  margin-bottom: 5px;
  font-size: 13px;
}

.create-edit-order .carts .lists .single p span {
  font-weight: 600;
}

.create-edit-order .carts .lists .single .hospital img {
  width: 60px;
  border: 1px solid #e4e6ef;
  border-radius: 0.475rem;
  padding: 5px 6px;
}

.create-edit-order .carts .lists .single:not(:last-child) {
  margin-bottom: 25px;
}

.create-edit-order .carts .lists {
  max-height: 350px;
  overflow-y: scroll;
}

.create-edit-order .carts .total {
  background-color: #f0f0f5;
  padding: 22px;
  margin-top: 15px;
}

.create-edit-order .carts .total .bg-primary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 16px 22px;
  border-radius: 10px;
  font-size: 16px;
}

.create-edit-order .carts .total .left {
  position: relative;
  display: flex;
  align-items: center;
}

.create-edit-order .carts .total .left::after {
  content: " ";
  position: absolute;
  border-left: 1px solid #fff;
  top: -5%;
  right: -80%;
  height: 120%;
  margin-top: auto;
  margin-bottom: auto;
}

.create-edit-order .carts .total .left img {
  margin-right: 13px;
  width: 20px;
}

.create-edit-order .carts .total .left p {
  padding-top: 5px;
}

.create-edit-order .carts .total .left p span {
  font-weight: 700;
}

.create-edit-order .patients {
  max-height: 350px;
  overflow-y: scroll;
}

.create-edit-order .patients label.single {
  display: block;
  position: relative;
  padding: 15px 15px 15px 50px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 15px; */
  border: 1px solid #e4e6ef;
  border-radius: 0.475rem;
  padding-left: 15px;
}

.create-edit-order .patients label.single input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.create-edit-order .patients label.single .checkmark {
  position: absolute;
  top: 40%;
  left: 15px;
  height: 19px;
  width: 19px;
  background-color: #eee;
  border-radius: 50%;
  display: none;
}

.create-edit-order .patients label.single input:checked ~ .checkmark {
  background-color: #31b5c4;
}

.create-edit-order .patients label.single .details h5 {
  font-size: 16px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.create-edit-order .patients label.single .details h5 span {
  margin-left: 5px;
}

.create-edit-order .patients label.single .details p {
  margin-bottom: 5px;
}

.create-edit-order .react-datepicker-wrapper {
  width: 100%;
}

.create-edit-order .css-yk16xz-control,
.create-edit-order .css-1pahdxg-control,
.create-edit-order .css-1fhf3k1-control {
  min-height: 43px;
}

.create-edit-order .lab-test-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f4f4f4;
  padding: 5px 10px;
  border-radius: 5px;
}

.create-edit-order .lab-and-button {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.create-edit-order .lab-and-button .css-2b097c-container {
  width: 88%;
  margin-bottom: 0 !important;
}

.create-edit-order .lab-and-button button {
  font-size: 19px;
}

/*============================ Order Add Modal =============================*/
.modal .create-order tbody input,
.modal .create-order tbody select {
  border: none;
  outline: none;
  border: 1px solid #777;
  border-radius: 5px;
  padding: 5px;
  width: 230px;
  background-color: transparent;
}

/* .modal .edit-order tbody select.single {
  width: 130px;
  border: none;
  outline: none;
  border: 1px solid #777;
  border-radius: 5px;
  padding: 5px;
  background-color: transparent;
} */

/* .modal .edit-order tbody textarea {
  border: none;
  outline: none;
  border: 1px solid #777;
  border-radius: 5px;
  padding: 5px;
  height: 75px;
} */

/* .modal .edit-order tbody input[type='text'] { */
.modal .edit-order tbody input.txt {
  width: 247px;
  border: none;
  outline: none;
  border: 1px solid #777;
  border-radius: 5px;
  padding: 6px 5px;
  margin-bottom: 10px;
}

.modal .create-order tbody .add-user .css-2b097c-container {
  width: 230px;
}

.modal .edit-order .estimate_date .react-datepicker-wrapper .react-datepicker__input-container input {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
}

.create-order-lab-pat {
  position: relative;
}

.create-order-lab-pat i.close {
  position: absolute;
  top: 15px;
  right: 23px;
  color: red !important;
  font-size: 23px;
  cursor: pointer;
}

.create-order-lab-pat i.close:hover {
  color: red !important;
}

.create-order-lab-pat .bor-right {
  border-right: 1px solid #777;
}

.create-order-lab-pat .patientsss .details {
  padding-left: 10px;
}

.create-order-lab-pat .patientsss .details .ag-sx span:first-child {
  text-transform: capitalize;
}

.create-order-lab-pat .patientsss label.single {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  border: 1px solid #777;
  border-radius: 5px;
}

.create-order-lab-pat.ite .patientsss {
  max-height: 300px;
  overflow: scroll;
  margin-bottom: 10px;
}

.create-order-lab-pat.ite .patientsss label.single {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 35px;
}

.create-order-lab-pat.ite .patientsss label.single .checkmark {
  top: 7px;
}

.create-order-lab-pat .patientsss label.single input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.create-order-lab-pat .patientsss label.single .checkmark {
  position: absolute;
  top: 11px;
  left: 10px;
  height: 19px;
  width: 19px;
  background-color: #eee;
  border-radius: 50%;
}

.create-order-lab-pat .patientsss label.single input:checked ~ .checkmark {
  background-color: #31b5c4;
}

.create-order-lab-pat form.pat-add .first .left input,
.create-order-lab-pat form.pat-add .last input {
  border: none;
  outline: none;
  border: 1px solid #777;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  background-color: transparent;
}

.create-order-lab-pat form.pat-add .first {
  align-items: center;
}

.create-order-lab-pat form.pat-add .first .right .male-female label {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
  padding: 0 18px 0 28px;
  color: #7c8399;
}

.create-order-lab-pat form.pat-add .first .right .male-female label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.create-order-lab-pat form.pat-add .first .right .male-female label .checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 3px solid #707070;
}

.create-order-lab-pat form.pat-add .first .right .male-female label input:checked ~ .checkmark {
  background-color: #707070;
}

.all-testsss {
  background-color: #47be7d78;
  padding: 3px 10px;
  border-radius: 25px;
  display: flex;
  align-items: center;
}

/* ======================================================================== */
/*=========================== Medai Queries ================================*/
/* ======================================================================== */

/*=========================== Homepage ===========================*/
@media only screen and (max-width: 767px) {
  .homepage .single {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .create-edit-order .col-md-6.left {
    padding-right: 10px;
    margin-bottom: 10px;
  }

  .create-edit-order .col-md-6.right {
    padding-left: 10px;
  }

  .create-edit-order .carts .total .bg-primary {
    flex-direction: column;
  }
}

/*=========================== Popover ===========================*/
.popover input {
  min-height: calc(1.5em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  border-radius: 0.475rem;
}

td .pop-edit {
  /* display: none; */
  opacity: 0;
}

td:hover .pop-edit {
  opacity: 1;
  /* display: block; */
}

td .edit-icn {
  /* display: none; */
  opacity: 0;
}

td:hover .edit-icn {
  /* display: block; */
  opacity: 1;
}

.pop-edit .icn-btn {
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  margin-left: 5px;
}

.pop-edit .popover {
  width: 300px;
  padding: 15px;
}

.pop-edit .footer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.pop-edit .footer button {
  padding: 5px 10px 3px 10px;
  background: #a1a5b76c;
  border-radius: 0.475rem;
  border: 1px solid #e4e6ef;
  border: none;
  outline: none;
}

.pop-edit .popover .react-datepicker-wrapper input {
  height: calc(1.5em + 1.1rem + 2px);
}

.select-pop .popover {
  width: 300px;
}

.select-pop .icn-btn {
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  margin-left: 5px;
}

.select-pop .footer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.select-pop .footer button {
  padding: 5px 10px 3px 10px;
  background: #a1a5b76c;
  border-radius: 0.475rem;
  border: 1px solid #e4e6ef;
  border: none;
  outline: none;
}

.select-pop .new-footer .btn-cancel {
  background: #1c1c1c12;
  border-radius: 0rem;
  border: none;
  font-size: 0.8rem;
  color: #7f8299;
}

.select-pop .new-footer .btn-save {
  border-radius: 0rem;
  border: none;
  font-size: 0.8rem;
}

@media only screen and (max-width: 991px) {
  td .pop-edit {
    /* display: block; */
    opacity: 1;
  }

  td .edit-icn {
    /* display: block; */
    opacity: 1;
  }
}

/*=========================== Order ===========================*/
.menu.menu-sub.menu-sub-dropdown.w-300px.w-md-325px.show {
  z-index: 105;
  position: fixed;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(747px, 222px);
}

/* .ordr-tab .table tr, */
.ordr-tab .table th,
.ordr-tab .table td {
  border: 1px solid #ddd;
}

.ordr-tab .table th {
  border: 0.5px solid #ddd;
}

.ordr-tab-new .table-row-dashed tr {
  border-bottom-width: 2px;
  border-bottom-style: dashed;
  border-bottom-color: #eff2f5;
}

.ordr-tab-new .text-dim-dark {
  color: #969696;
}

.table-responsive::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

/* Track */
.table-responsive::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 2px;
}

/* Handle */
.table-responsive::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 2px;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background: grey;
}

td .report-delivery {
  position: relative;
}

td .report-delivery .select-pop {
  position: absolute;
  top: 0;
  right: 0;
}

.ordr-tab table thead {
  position: sticky;
  top: 0;
  z-index: 99;
  background: #eee;
}

.bg-dim-primary {
  background-color: #e1ffff;
}
.bg-dim-danger {
  background-color: #fdecf0;
}
.bg-dim-warning {
  background-color: #fff9e8;
}
.bg-dim-green {
  background-color: #e9fff4;
}
.bg-dim-purple {
  background-color: #f8f5ff;
}

.bg-purple {
  background-color: #7338ea;
}

.text-purple {
  color: #7338ea;
}

.text-light-dark {
  color: #7f8299;
}

.input-bg {
  background-color: #f5f8fa !important;
  color: #5e6278 !important;
}

.input-bg:hover {
  background-color: #eef3f7 !important;
  color: #5e6278 !important;
}

.btn-input {
  background-color: #ecf0f7 !important;
  border-color: #ecf0f7 !important;
  color: #5e6278 !important;
}

.btn-input:hover,
.btn-input:active {
  background-color: #dbe3e9 !important;
  border-color: #dbe3e9 !important;
  color: #5e6278 !important;
}

.btn-input i {
  color: #5e6278;
}

/* select */
#order-stat-drop {
  transform: translate(0px, 0px) !important;
  top: 100% !important;
}

#filter-drop {
  /* transform: translate(-90%, 0px) !important; */
  transform: none !important;
  right: 10px !important;
  left: auto !important;
  top: 100% !important;
  margin-top: 1px !important;
  width: max-content !important;
  min-width: 145px;
  box-shadow: #27134e26 0px 0px 10px 2px !important;
}

#filter-drop-left {
  transform: none !important;
  left: -5px !important;
  top: 100% !important;
  margin-top: 1px !important;
  width: max-content !important;
  min-width: 140px;
  box-shadow: #27134e26 0px 0px 10px 2px !important;
}

/* select end */

@media only screen and (min-width: 768px) {
  .ordr-tab table thead tr th:first-child,
  .ordr-tab table tbody tr td:first-child {
    position: sticky;
    left: 0px;
    z-index: 98;
    background: #fcfcfc;
  }

  .ordr-tab table thead tr th:nth-child(2),
  .ordr-tab table tbody tr td:nth-child(2) {
    position: sticky;
    left: 125px;
    z-index: 98;
    background: #f7f7f7;
  }

  .ordr-tab table thead tr th:nth-child(3),
  .ordr-tab table tbody tr td:nth-child(3) {
    position: sticky;
    left: 275px;
    z-index: 98;
    background: #fcfcfc;
  }

  .ordr-tab table thead tr th:first-child,
  .ordr-tab table thead tr th:nth-child(2),
  .ordr-tab table thead tr th:nth-child(3) {
    background: #eee;
  }

  .ordr-tab table tbody tr.locked td:first-child,
  .ordr-tab table tbody tr.locked td:nth-child(2),
  .ordr-tab table tbody tr.locked td:nth-child(3) {
    background-color: #fee;
    /* z-index: 99; */
  }

  /* .ordr-tab .table .locked td {
    border: 1px solid #ddd;
  } */
}

/* @media only screen and (max-width: 767px) { */
.table.mt-hide th.mt,
.table.mt-hide td.mt {
  display: none;
}
/* } */

.account-tab table thead {
  background: #eee;
  position: sticky;
  top: 0;
  z-index: 99;
}

.account-tab .table th {
  border: 0.5px solid #ddd;
}

.account-tab .table td,
.account-tab .table th {
  border: 1px solid #ddd;
}

.account-tab td .payment-method .edit-icn {
  opacity: 1;
}

.account-tab td .payment-method .edit-icn::before {
  content: "Add";
  font-family: "Poppins", Helvetica, sans-serif;
  font-weight: 500;
  background: #1bc5bd;
  color: #fff;
  padding: 1px 6px;
  border-radius: 5px;
  margin-left: -7px;
}

.account-tab td.method .lists .single {
  display: flex;
  margin-bottom: 0.5rem;
}

.account-tab td.method .lists .single .edit-icn {
  opacity: 0;
}

.account-tab td.method .lists .single:hover .edit-icn {
  opacity: 1;
}

.dataTables_wrapper table .text-truncate {
  font-size: 12px;
  font-weight: 500;
  font-stretch: condensed;
}

.dataTables_wrapper table tbody td {
  font-size: 12px;
}

.dataTables_wrapper table tbody td .text-dim-dark.fo-11 {
  font-size: 11px;
}

.dataTables_wrapper table tbody td .status-btn {
  max-width: 100px;
  /* min-width: 80px; */
  padding: 4px 12px;
  font-size: 12px;
}

/* .dataTables_wrapper table tbody td .edit-btn {
  max-width: 73px;
} */

.dataTables_wrapper table tbody td .edit-btn button {
  display: flex;
  align-items: center;
  padding: 5px 13px !important;
}

.ord-edt-itm-mdl-add .lab-and-button {
  display: flex;
  justify-content: space-between;
}

.ord-edt-itm-mdl-add .lab-and-button .css-2b097c-container {
  width: 90%;
  margin-bottom: 0 !important;
}

.ord-edt-itm-mdl-add .lab-and-button button {
  font-size: 19px;
}

.ord-edt-itm-mdl-add .lab-test-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 10px 15px;
}

.order-page .head-item {
  justify-content: space-between;
  flex-grow: 1;
}

.order-page .head-item .right {
  display: flex;
}

.package-item-edit .css-yk16xz-control,
.package-item-edit .css-1pahdxg-control {
  min-height: 48px;
  border: 1px solid #e4e6ef;
  border-radius: 0.475rem;
  padding: 0 7px;
  font-size: 14px;
  color: #5f6278;
}

.package-item-edit .lab-and-button {
  display: flex;
  justify-content: space-between;
}

.package-item-edit .lab-and-button .css-2b097c-container {
  width: 94%;
  margin-bottom: 0 !important;
}

.package-item-edit .lab-and-button button {
  font-size: 19px;
}

.package-item-edit .lab-test-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 10px 15px;
}

@media screen and (max-width: 767px) {
  .order-page .head-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-page .head-item .right {
    flex-direction: column;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1314px) {
  .dataTables_wrapper table tbody td .edit-btn button i {
    font-size: 11px;
  }

  .dataTables_wrapper table tbody td .status-btn {
    max-width: 95px;
  }

  .dataTables_wrapper table .text-truncate,
  .dataTables_wrapper table tbody td {
    font-size: 11px;
  }

  .dataTables_wrapper table tbody td .text-dim-dark.fo-11 {
    font-size: 10px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1290px) {
  .dataTables_wrapper table .text-truncate,
  .dataTables_wrapper table tbody td {
    font-size: 10px;
    padding: 0 !important;
  }

  .dataTables_wrapper table tbody td .text-dim-dark.fo-11 {
    font-size: 9px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1255px) {
  .select-search-ot {
    width: 210px;
  }

  .input-search-ot {
    width: 270px;
  }
}

/*================================Medicine========================*/
#resized_scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.li-disabled {
  pointer-events: none;
  /* opacity:0.6; */
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-none {
  cursor: none;
}

.form-control::placeholder {
  font-weight: 400;
}

.custom-file-med {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  margin-bottom: 0;
  cursor: pointer;
}

.custom-file-med-input {
  position: relative;
  z-index: 2;
  width: 100%;
  /* height: calc(1.5em + 1.3rem + 2px); */
  /* height: calc(1.5em + 1.65rem + 2px); */
  margin: 0;
  opacity: 0;
  cursor: pointer;
}
.custom-file-med-input:focus ~ .custom-file-med-label {
  border-color: #30e3da;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.custom-file-med-input[disabled] ~ .custom-file-med-label,
.custom-file-med-input:disabled ~ .custom-file-med-label {
  background-color: #f3f6f9;
}
.custom-file-med-input:lang(en) ~ .custom-file-med-label::after {
  content: "Browse";
  background-color: #7c839950;
  min-height: calc(1.5em + 1.65rem + 2px);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.custom-file-med-input ~ .custom-file-med-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-med-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  /* height: calc(1.5em + 1.3rem + 2px); */
  height: calc(1.5em + 1.65rem + 2px);
  /* padding: 0.65rem 1rem; */
  padding: 0.825rem 1.5rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  background-color: #f5f8fa;
  border: 0px solid #e4e6ef;
  border-radius: 0.42rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
}
.custom-file-med-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  /* height: calc(1.5em + 1.3rem); */
  height: calc(1.5em + 1.65rem + 2px);
  /* padding: 0.65rem 1rem; */
  padding: 0.825rem 1.5rem;
  line-height: 1.5;
  color: #3f4254;
  content: "Browse";
  background-color: #f3f6f9;
  border-left: inherit;
  border-radius: 0 0.42rem 0.42rem 0;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

#medicineTextArea::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.custom_scrollbar::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

/* Track */
#medicineTextArea::-webkit-scrollbar-track,
.custom_scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
#medicineTextArea::-webkit-scrollbar-thumb,
.custom_scrollbar::-webkit-scrollbar-thumb {
  background: #8cccc9;
  border-radius: 10px;
}

#medicineTextArea::-webkit-scrollbar-thumb:hover,
.custom_scrollbar::-webkit-scrollbar-thumb:hover {
  background: #1bc5bd;
}

.text-placeholder-color {
  color: #a3a7b9;
  font-weight: 400;
}

.form-check.form-check-solid .form-check-custom,
.form-check.form-check-solid .form-check-custom:focus {
  height: 1.6rem;
  /* border: 1px solid rgba(124, 131, 153, 0.19); */
  background-color: #a3a7b98f;
}
.form-check.form-check-solid .form-check-custom:checked {
  background-color: #31b5c4;
}

.category-select {
  height: calc(1.5em + 1.72rem + 2px);
  padding: 0.825rem 1.5rem;
  font-weight: 400;
}

.category-modal-wrapper {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 5;
  background-color: #7c839950;
}

.category-list td,
.category-list th {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

/*********** Notification *******************/
.amarlab-notification {
  font-family: "Poppins", Helvetica, sans-serif;
}

/********* old custom file ****************/

.custom-file {
  width: 100%;
}

.custom-file-input:focus ~ .custom-file-label {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.custom-file-label {
  text-align: left;
}
.custom-file-label:after {
  float: left;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  font-weight: 400;
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.5rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #30e3da;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #f3f6f9;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  height: calc(1.5em + 1.3rem + 2px);
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.5rem + 2px);
  padding: 0.65rem 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #7c8399;
  background-color: #ffffff;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.3rem);
  padding: 0.65rem 1rem;
  line-height: 1.5;
  color: #7c8399;
  content: "Browse";
  background-color: #f3f6f9;
  border-left: inherit;
  border-radius: 0 0.42rem 0.42rem 0;
}

#image-popover-contained {
  margin-top: 2px !important;
  transform: translate(70px, 20px) !important;
  margin-bottom: 5rem !important;
}
#image-popover-contained .popover-body {
  padding: 1rem 1.25rem;
}

#image-popover-contained input {
  height: calc(1.5em + 1rem + 2px) !important;
}

#image-popover-contained .custom-file {
  height: calc(1.5em + 1rem + 2px) !important;
}
#image-popover-contained .custom-file-input:lang(en) ~ .custom-file-label::after {
  height: calc(1.5em + 1rem + 1px) !important;
}
#image-popover-contained .custom-file-label {
  height: calc(1.5em + 1rem + 2px) !important;
  padding: 0.55rem 1rem;
}

/*test code riad need to remove*/
.use-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  /* min-height: 200px !important; */
}
tr.h_px {
  height: 1px;
}

tr.h_px.locked {
  background-color: #ffdddd80;
}

tr.row-highlight {
  background-color: #ffdddd80;
}

td.h_px_td {
  height: inherit;
}

.due_date .react-datepicker-popper {
  width: 340px;
}

.use-flex div:nth-child(1) {
  margin-bottom: auto;
}

.use-flex div:nth-child(2) {
  margin-top: auto;
}
.sticky-header table thead {
  position: sticky;
  z-index: 99;
  top: 0;
  background-color: #fff;
}

/*=========================================== Reference========================*/
.ref-page table thead tr th {
  border-bottom-width: 2px;
  font-weight: 500;
}

.ref-page .form-check.form-check-solid .form-check-input:checked {
  background-color: #4caf50;
}

.ref-page .search-box .input-group {
  width: 300px;
}

.ref-page .search-box .input-group input {
  border: none;
  outline: none;
  background-color: #f6f8fa;
  font-size: 14px;
  padding: 11px 0;
  padding-left: 20px;
  border: 1px solid #f6f8fa;
}

.ref-page .search-box .input-group button {
  border: 1px solid #f6f8fa;
  padding-right: 20px;
}

.ref-page .search-box .input-group button i {
  font-size: 15px;
}

.ref-add-page .item-title {
  font-size: 14px;
  margin-bottom: 8px;
  /* display: inline-block; */
}

.ref-add-page .react-datepicker-wrapper {
  width: 100%;
}

.ref-add-page .cupon-title {
  font-size: 14px;
  padding: 18px 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
  background-color: #f1faff;
  border-radius: 6px;
  color: #404254;
  font-weight: 500;
}

.ref-add-page .col-f-item {
  padding-right: 28px;
}

.ref-add-page .col-l-item {
  padding-left: 28px;
}

.ref-add-page .f-f-item {
  padding-right: 15px;
}

.ref-add-page .f-l-item {
  padding-left: 15px;
}

.ref-add-page .cup-add-btn {
  border: none;
  outline: none;
  background-color: #f5f8fa;
  padding: 18px 36px;
  border-radius: 6px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: #404254;
}

.ref-add-page .form-select-sloid {
  background-color: #f5f8fa;
  border-color: #f5f8fa;
  color: #a1a5b7;
  font-weight: 400;
}

.ref-add-page .cupon-lists .single {
  background-color: #f5f8fa;
  border-radius: 6px;
  padding: 16px 20px;
  margin-bottom: 20px;
}

.ref-add-page .cupon-lists .single .title {
  color: #1c1c1c;
  font-size: 14px;
  margin-bottom: 4px;
}

.ref-add-page .cupon-lists .single .date {
  color: #5f6278;
  font-size: 10px;
  margin-bottom: 16px;
}

.ref-add-page .cupon-lists .single i {
  font-size: 16px;
}

.ref-add-page .category-add {
  display: flex;
}

.ref-add-page .category-add button {
  background-color: #f5f8fa;
  margin-left: 10px;
}

.channel-p .create-channle {
  position: relative;
}

.channel-p .create-form,
.ref-add-page .create-form {
  position: absolute;
  top: 0;
  background: #fff;
  z-index: 999;
  width: 700px;
  right: 0;
  box-shadow: 0px 3px 26px #1c1c1c1a;
  padding: 40px 55px;
  border-radius: 6px;
  transition: all 1s ease-in-out;
}

.channel-p .switc {
  display: flex;
  align-items: center;
}

.channel-p .switc input.form-check-input {
  margin-left: 10px;
  height: 26px;
  width: 44px;
}

.channel-p .col-f {
  padding-right: 20px;
}

.channel-p .col-l {
  padding-left: 20px;
}

.ref-det-page .head-details .col-f-item,
.ref-det-page .head-details .col-l-item {
  display: flex;
  align-items: flex-start;
}

.ref-det-page .head-details button {
  font-size: 14px;
  padding: 18px 46px !important;
}

.ref-det-page .head-details .details {
  margin-left: 25px;
}

.ref-det-page .head-details .details .head {
  display: flex;
}

.ref-det-page .head-details .details .head .analytic h5 {
  font-size: 22px;
  margin-bottom: 6px;
}

.ref-det-page .head-details .details .head .analytic p {
  font-size: 18px;
  margin-bottom: 0;
  margin-right: 25px;
}

.ref-det-page .head-details .details .body {
  display: flex;
  margin-top: 15px;
  margin-bottom: 22px;
}

.ref-det-page .head-details .details .body .single {
  border: 1px dashed #e4e6ef;
  border-radius: 8px;
  width: 123px;
  margin-right: 20px;
  padding: 12px 18px;
}

.ref-det-page .head-details .details .body .single p.desc {
  font-size: 12px;
  margin-bottom: 0;
  color: #969696;
}

.ref-det-page .head-details .details p.title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 1px;
  color: #1c1c1c;
}

.ref-det-page .head-details .details .reference {
  margin-bottom: 22px;
}

.ref-det-page .head-details .details .reference .desc,
.ref-det-page .head-details .details .foot .desc {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 4px;
}

.ref-det-page .head-details .col-f-item {
  border-right: 1px solid #e5e5eb;
}

.ref-det-page .head-details {
  border-bottom: 1px solid #e5e5eb;
  padding-bottom: 20px;
}

.ref-det-page .head-details .details.info {
  margin-left: 0;
  display: flex;
  justify-content: space-between;
}

.ref-det-page .head-details .details.info p.desc {
  font-size: 14px;
  margin-top: 4px;
  color: #404254;
}

.ref-det-page .head-details .details.info .individual:not(:last-child) {
  margin-bottom: 22px;
}

.ref-det-page .head-details .details.info .left,
.ref-det-page .head-details .details.info .right {
  margin-right: 30px;
}

.ref-det-page .head-details .col-l-item {
  justify-content: space-between;
}

.ref-det-page table thead tr th {
  font-size: 14px;
  font-weight: 500;
  border-bottom-width: 2px;
}

.ref-det-page table tbody tr td {
  font-size: 13px;
  font-weight: 400;
}

.ref-det-page table tbody tr td .fw-500 {
  font-weight: 500;
}

.ref-det-page .back-btn {
  display: flex;
  align-items: center;
  padding: 18px 25px 18px 30px !important;
}

/*=========================================== Bulk Uploader========================*/

.bulk-upload .lab-and-button .css-2b097c-container {
  width: auto !important;
  flex-grow: 1;
}

.bulk-upload .text-placeholder {
  font-weight: normal;
  /* color: #808080; */
}

.bulk-upload .text-placeholder::placeholder {
  font-weight: normal;
  color: #808080;
  font-size: 13px;
}

.bulk-upload .react-datepicker__input-container input::placeholder {
  font-weight: normal;
  color: #808080;
  font-size: 13px;
}

.bulk-upload .custom-file,
.bulk-upload .custom-file input,
.bulk-upload .custom-file .custom-file-label,
.bulk-upload .refcls {
  height: 43px;
}

.bulk-upload .custom-file .custom-file-label {
  font-size: 13px;
}

.bulk-upload .custom-file .custom-file-label::after {
  height: 41.3px;
}

.bulk-upload .custom-file-input:focus ~ .custom-file-label {
  border-color: #707070;
}

/* ======================================================================= */
/*================================= Login =================================*/
/* ======================================================================= */

.custom-dp .react-datepicker-wrapper {
  display: block;
}

.custom-dp .react-datepicker-wrapper input {
  margin-bottom: 0 !important;
}

/* ======================================================================= */
/*================================= users =================================*/
/* ======================================================================= */
.menu-user-dropdown {
  z-index: 105 !important;
  position: absolute !important;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(-70%, 0) !important;
  top: 50px !important;
  right: 110px !important;
}

.patient-dob .react-datepicker__input-container input {
  border: none;
  outline: none;
  background: #f5f8fa;
  color: #5e6278;
  border: #f5f8fa;
  transition: color 0.2s ease, background-color 0.2s ease;
  min-height: calc(1.5em + 1.65rem + 2px);
  padding: 0.825rem 1.5rem;
  font-size: 1.15rem;
  border-radius: 0.475rem;
  width: 100%;
}

.patient-dob .react-datepicker-wrapper {
  width: 100%;
}
